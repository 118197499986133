import { MenuEntry } from '@pancakeswap-libs/uikit'

const config: MenuEntry[] = [
  {
    label: 'Home',
    icon: 'HomeIcon',
    href: '/',
  },
  {
    label: 'Trade',
    icon: 'TradeIcon',
    items: [
      {
        label: 'Exchange',
        href: 'https://exchange.yieldstar.farm/#/swap',
      },
      {
        label: 'Liquidity',
        href: 'https://exchange.yieldstar.farm/#/pool',
      },
    ],
  },
  {
    label: 'Farms',
    icon: 'FarmIcon',
    href: '/farms',
  },
  {
    label: 'Pools',
    icon: 'RedditIcon',
    href: '/pools',
  },
 // {
 //   label: 'MemePools',
 //   icon: 'PoolIcon',
 //   href: '/memepools',
//  },
  // {
  //   label: 'Pools',
  //   icon: 'PoolIcon',
  //   href: '/pools',
  // },
  {
     label: 'Lottery (Coming Soon)',
     icon: 'TicketIcon',
     href: '',
   },
   {
     label: 'NFT (Coming Soon)',
     icon: 'NftIcon',
     href: '',
   },
  {
    label: 'Info',
    icon: 'InfoIcon',
    items: [
           {
        label: 'CoinGecko',
        href: 'https://www.coingecko.com/',
      },
      {
        label: 'CoinMarketCap',
        href: 'https://coinmarketcap.com/',
      },
    ],
  },
  {
    label: 'More',
    icon: 'MoreIcon',
    items: [
      {
        label: 'Github',
        href: 'https://github.com//',
      },
      {
        label: 'Docs',
        href: 'https://yield-star.gitbook.io/yield-star/',
      },
      {
        label: 'Blog',
        href: 'https://medium.com/@yieldstar',
      },
      {
        label: "YouTube",
        href: 'https://youtube.com',
      },
    ],
  },
  {
    label: 'Audit (in progress)',
    icon: 'AuditIcon',
    href: '',
  },
]

export default config
